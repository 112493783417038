import React, { useState, useContext, useEffect } from "react";
import moment from "moment";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FilledInput from "@material-ui/core/FilledInput";
import {
  useTheme,
  makeStyles,
  withStyles,
  useMediaQuery,
} from "@material-ui/core";

import { Link } from "gatsby-material-ui-components";

import MoreButton from "./MoreButton";
import FeaturedPostSlider from "./FeaturedPostSlider";

import useLanguage from "../hooks/useLanguage";
import useUrl from "../hooks/useUrl";

import i18nContext from "../i18n-context";
import { formatMomentDateByLang } from "../utils";

const PostTable = ({
  title,
  posts,
  featuredPosts,
  openPostsInNewTab,
  showAuthor,
  anchorId
}) => {
  const lang = useLanguage();
  const muiTheme = useTheme();
  const classes = useStyles();
  const url = useUrl();
  const i18n = useContext(i18nContext);
  const [year, setYear] = useState("");
  const [displayLength, setDisplayLength] = useState(3);
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));

  useEffect(() => {
    if (availableYears.length > 0) setYear(availableYears[0].toString());
  }, []);

  const availableYears = [
    ...new Set(
      posts.map((post) => post.contentGenericGroup?.publicationDate.year())
    ),
  ].sort((a, b) => b - a);

  let allYears = [];
  for (
    let i = availableYears[0];
    i >= availableYears[availableYears.length - 1];
    i--
  ) {
    allYears = [...allYears, i.toString()];
  }

  function compare( a, b ) {
    if ( a.contentGenericGroup.publicationDate < b.contentGenericGroup.publicationDate ){
      return 1;
    }
    if ( a.contentGenericGroup.publicationDate > b.contentGenericGroup.publicationDate ){
      return -1;
    }
    return 0;
  }

  const postsFilteredByYears = posts.filter(
    (pressRelease) =>
      pressRelease.contentGenericGroup?.publicationDate.year().toString() ===
      year
  ).sort(compare);

  postsFilteredByYears.forEach((post) => {
    formatMomentDateByLang(post.contentGenericGroup.publicationDate, lang);
  });

  // const imageOnLeftTextOnRight = url.includes("/media") ? true : null;
  // const light = url.includes("/media") ? true : null;
  const imageOnLeftTextOnRight = null;
  const light = null
  
  return (
    <div className={classes.section} id={anchorId ? decodeURI(anchorId) : ""}>
      {title && (
        <div className={classes.headingRoot}>
          <Typography
            variant="h4"
            className={classes.headingText}
            align={isDesktop ? "left" : "center"}
          >
            {title}
          </Typography>
          <Divider className={classes.headingDivider} />
        </div>
      )}

      {Array.isArray(featuredPosts) && featuredPosts?.length > 0 && (
        <FeaturedPostSlider 
          posts={featuredPosts} 
          imageOnLeftTextOnRight={imageOnLeftTextOnRight} 
          light={light} 
          arrowPos={{
            buttonOffsetY: -213,
            leftButtonOffsetX: -38,
            rightButtonOffsetX: 38,
          }}
        />
      )}

      {posts.length > 0 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="baseline"
          spacing={2}
        >
          <Grid item>
            <Typography variant="body2">
              <strong>{i18n.sectionHeadings.browseByYear}</strong>
            </Typography>
          </Grid>

          <Grid item>
            <StyledSelect
              variant="filled"
              input={<StyledFilledInput />}
              value={year}
              onChange={(e) => setYear(e.target.value)}
            >
              {allYears.map((year) => (
                <MenuItem key={`${title}-year-filter-${year}`} value={year}>
                  {year}
                </MenuItem>
              ))}
            </StyledSelect>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={4} className={classes.pressReleaseList}>
        {postsFilteredByYears.length > 0 ? (
          postsFilteredByYears.slice(0, displayLength).map((item,index) => {
            return (
              <Grid
                item
                xs={12}
                key={ `url${index}:`+item.uri}
                className={classes.pressReleaseItem}
              >
                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item xs={12} md={3}>
                    <Typography variant="body1">
                      {moment(item.contentGenericGroup.publicationDate).format(
                        lang === "en" ? "MMMM D, YYYY" : "LL"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Link
                      underline="none"
                      to={item.uri}
                      target={openPostsInNewTab ? "_blank" : "_self"}
                    >
                      {showAuthor && (
                        <Typography variant="body2">
                          {item.contentGenericGroup.author}
                        </Typography>
                      )}
                      <Typography
                        variant="h5"
                        className={classes.pressReleaseTitle}
                      >
                        {item.title}
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.pressReleaseDivider} />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} className={classes.pressReleaseItem}>
            <Grid container spacing={1} alignItems="flex-start">
              <Grid item xs={12} md={9}>
                <Typography variant="h5" className={classes.pressReleaseTitle}>
                  {i18n.noContentIsAvailable}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid container justifyContent="center">
        {postsFilteredByYears.length > displayLength && (
          <MoreButton
            color={muiTheme.palette.error.main}
            textColor="black"
            onClick={() => setDisplayLength((prevLength) => (prevLength += 3))}
          >
            {i18n.showMore}
          </MoreButton>
        )}
      </Grid>
    </div>
  );
};

const StyledFilledInput = withStyles((theme) => ({
  root: { borderRadius: 2 },
}))((classes) => <FilledInput disableUnderline {...classes} />);

const StyledSelect = withStyles((theme) => ({
  select: {
    padding: 6,
    paddingLeft: 12,
  },
}))((classes) => (
  <Select
    {...classes}
    MenuProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    }}
  />
));

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(8),
  },
  headingRoot: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(4),
    },
  },
  headingText: {
    fontFamily: "Open Sans",
  },
  headingDivider: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `-${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(8)}px`,
    },
  },
  pressReleaseList: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  pressReleaseTitle: {
    fontFamily: "Open Sans",
    marginBottom: theme.spacing(2),
  },
  pressReleaseDivider: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `-${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
}));

export default PostTable;
