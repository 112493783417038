import React, { useContext, useEffect } from "react";
import parse from "html-react-parser";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Typography from "@material-ui/core/Typography";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

import Layout from "../components/layout";
import Banner from "../components/Banner";
import Container from "../components/Container";
import PostTable from "../components/PostTable";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";

import i18nContext from "../i18n-context";
import { mapMomentToPosts } from "../utils";

const MediaPage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const classes = useStyles();
  const i18n = useContext(i18nContext)[lang];
  const isPhone = useMediaQuery(useTheme().breakpoints.down("xs"));
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));

  const { title, excerpt, translated, featuredImage = {} } = pageContext;
  const sourceUrl = featuredImage?.node?.sourceUrl || "";
  const ogDescription = excerpt ? parse(excerpt)[0]?.props?.children : "";

  const {
    allWpPressRelease: { nodes },
  } = data;

  const pressReleaseList = mapMomentToPosts(
    nodes,
    "contentGenericGroup.publicationDate"
  );
  
  const sections = [
    {
      anchorId:i18n.anchor.mediaCoverage,
      title: i18n.media.mediaCoverage,
      nodes: pressReleaseList
        .filter((pressRelease) => pressRelease.pressGroup?.pressType === "2" && !pressRelease.contentGenericGroup?.isFeatured )
        .map((pressRelease) => ({
          ...pressRelease,
          uri: pressRelease.pressGroup.newsLink,
        })),
      featured: pressReleaseList
        .filter(
          (pressRelease) =>
            pressRelease.pressGroup?.pressType === "2" &&
            pressRelease.contentGenericGroup?.isFeatured
        )
        .map((pressRelease) => ({
          ...pressRelease,
          synopsis: pressRelease.contentGenericGroup.synopsis,
          uri: pressRelease.pressGroup.newsLink,
        })),
    },
    {
      anchorId: i18n.anchor.pressRelease,
      title: i18n.media.pressRelease,
      nodes: pressReleaseList
        .filter((pressRelease) => pressRelease.pressGroup?.pressType === "1")
        .map((pressRelease) => ({
          ...pressRelease,
          uri: pressRelease.pressReleasePdfFiles,
        })),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      let elemId = window.location.href.substr(
        window.location.href.indexOf("#") + 1
      );
      const elem = document.getElementById(elemId);
      const appbar = document.getElementById("appbar");
      if (elem && appbar) {
        const appbarHeight = !isPhone
          ? appbar.offsetHeight + 15
          : appbar.offsetHeight;
        const elemPos = elem.getBoundingClientRect().top;
  
        const bodySize = document.body || document.getElementsByTagName("body")[0];
        const scrollTop = (window.pageYOffset || document.documentElement.scrollTop || bodySize.scrollTop);
  
        window.scrollTo({
          top: elemPos - appbarHeight + scrollTop,
          behavior: "smooth",
        });
      }
    }, 500);
  }, [pressReleaseList]);

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title}`}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={sourceUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content={sourceUrl} />
      </Helmet>

      <Banner title={title} bannerUrl={sourceUrl} />
      <Container>
        <Typography
          variant="body1"
          className={classes.description}
          component="div"
        >
          {parse(excerpt)}
        </Typography>

        {sections.map((section) => {
          return (
            <PostTable
              anchorId={section.anchorId}
              title={section.title}
              posts={section.nodes}
              featuredPosts={section.featured}
              key={`media-${section.title}`}
              openPostsInNewTab
              showAuthor
            />
          );
        })}
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  section: {
    marginBottom: theme.spacing(8),
  },
  headingRoot: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(4),
    },
  },
  headingText: {
    fontFamily: "Open Sans",
  },
  headingDivider: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `-${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(8)}px`,
    },
  },
  pressReleaseList: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  // pressReleaseItem: {
  //   marginTop: theme.spacing(2),
  //   marginBottom: theme.spacing(2),
  // },
  pressReleaseTitle: {
    fontFamily: "Open Sans",
    marginBottom: theme.spacing(2),
  },
  pressReleaseDivider: {
    overflow: "hidden",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      marginLeft: `-${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
}));

export const query = graphql`
  query getPressReleasesQuery($locale: String) {
    allWpPressRelease(
      sort: { fields: contentGenericGroup___publicationDate, order: DESC }
      filter: { locale: { locale: { eq: $locale } } }
    ) {
      nodes {
        title
        uri
        pressReleasePdfFiles
        pressGroup {
          pressType
          newsLink
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        contentGenericGroup {
          author
          isFeatured
          synopsis
          publicationDate
          mobileFeaturedImage {
            sourceUrl
          }
          tabletFeaturedImage {
            sourceUrl
          }
        }
      }
    }
  }
`;

export default MediaPage;
